import React, { useEffect, useState } from 'react'
import '../styles/priorities.scss'
import { GiRecycle } from 'react-icons/gi'

export const Priorities = ({children,isActive}) => {
    const [showLogo,setShowLogo] = useState(false)
    const [currentCard,setCurrentCard] = useState(0)
    const [clInterval,setClInterval] = useState(null)
    useEffect(()=>{
        if(isActive){
            setTimeout(()=>{
                setShowLogo(true)
            },800)
            let interval = setInterval(()=>{
                setCurrentCard(prev => prev >= 2 ? 0 : prev + 1)
            },8000) 
            setClInterval(interval)
        }else {
            clearInterval(clInterval)
            setShowLogo(false)
        }
    },[isActive])
    return (
        <section className={`priorities ${isActive && 'showen'}`} id='priorities'>
            <div className='container priorities-container'>
                <section className='holder' style={{'--t':currentCard}}>
                    {children}
                    <h2 className='main-heading'>أولوياتنا</h2>
                    <div className='swiper'>
                        <div className='cards-holder'>
                            <article className='card'>
                                <div className='animation'>
                                    <img className='smile' src="/smile.png" alt="" />
                                    <img className='like' src="/like.png" alt="" />
                                    <img className='heart' src="/heart.png" alt="" />
                                    <img className='smile2' src="/smile.png" alt="" />
                                    <img className='like2' src="/like.png" alt="" />
                                    <img className='heart2' src="/heart.png" alt="" />
                                </div>
                                <h2 className='priority TXT-heading3'>رضا المستخدمون</h2>
                                <p className='desc TXT-footer'> بتصميم واجهة جذابة سهلة الفهم وخالية من التعقيد وجعل النظام خفيف وسريع. </p>
                            </article>
                            <article className='card'>
                                <div className='animation'>
                                    <div className="images">
                                        <img className='image-1' src="/money.png" alt="" />
                                        <img className='image-2' src="/money.png" alt="" />
                                        <img className='image-3' src="/money.png" alt="" />
                                        <img className='image-4' src="/money.png" alt="" />
                                    </div>
                                </div>
                                <h2 className='priority TXT-heading3'>زيادة الأرباح</h2>
                                <p className='desc TXT-footer'>عن طريق زيادة عدد المستخدمون من خلال إعطاع طابع إحترافي للمشروع و بناء الثقة.</p>
                            </article>
                            <article className='card'>
                                <div className='animation'>
                                    <div className='circle'><span className='icon'>{GiRecycle({})}</span></div>
                                    <div className='line-1 line'></div>
                                    <div className='line-2 line'></div>
                                    <div className='line-3 line'></div>
                                    <div className='line-4 line'></div>
                                    <span className='bullet-1 bullet'></span>
                                    <span className='bullet-2 bullet'></span>
                                    <span className='bullet-3 bullet'></span>
                                    <span className='bullet-4 bullet'></span>
                                    <span className='bullet-5 bullet'></span>
                                    <span className='bullet-6 bullet'></span>
                                    <span className='bullet-7 bullet'></span>
                                    <span className='bullet-8 bullet'></span>
                                    <span className='f-bullet' style={{'--i':0.0}}></span>
                                    <span className='f-bullet' style={{'--i':0.8}}></span>
                                    <span className='f-bullet' style={{'--i':1.6}}></span>
                                    <span className='f-bullet' style={{'--i':2.4}}></span>
                                </div>
                                <h2 className='priority TXT-heading3'>تقليل المهام</h2>
                                <p className='desc TXT-footer'>بحيث يتم إنشاء أنظمة لأتمتة المهام المتكررة فيمكنك بالتالي التركيز على تطوير مروعشك.</p>
                            </article>
                        </div>  
                    </div>
                    <div className={`velocity-logo ${showLogo && 'show-logo'}`}>
                        <img className='v-logo' src="/v-logo.png" alt="" />
                        {/* <p>V</p>
                        <p>C</p> */}
                    </div>
                    <span className={`logo-line ${showLogo && 'animate'}`}></span>
                </section>
            </div>
        </section>
    )
}
