import React, { useEffect, useState } from 'react'
import '../styles/aboutUs.scss'
import { MdKeyboardDoubleArrowDown } from 'react-icons/md'

const AboutUs = ({children,isActive}) => {
    const words = [
        {word:'إنشاء مواقع إلكترونية',l:580,m:400,s:300}, 
        {word:'تطوير تطبيقات',l:370,m:260,s:190}, 
        {word:'تصميم واجهات',l:400,m:280,s:200}, 
        {word:'بناء أنظمة',l:275,m:200,s:140},
        {word:'تحويل خيالك إلى واقع',l:560,m:390,s:290}
    ];
    const [currentWord,setCurrentWord] = useState({word:'إنشاء مواقع إلكترونية',l:580,m:400,s:300});
    const [isShowen,setIsShowen] = useState(true);
    const [showLogo,setShowLogo] = useState(false);

    useEffect(()=>{
        let index = 1
        setInterval(()=>{
            if(index === words.length) index = 0
            setIsShowen(false)
            setTimeout(()=>{
                setCurrentWord(words[index])
                setIsShowen(true)
                index += 1
            },300)
        },4200)
        setIsShowen(true)
    },[])

    useEffect(()=>{
        if(isActive){
            setTimeout(()=>{
                setShowLogo(true)
            },1200)
        }else {
            setShowLogo(false)
        }
    },[isActive])
    return (
        <section className={`about-us-section ${isActive && 'active'}`} id="about-us">
            <div className='container about-us-container'>
                <div className='holder'>
                    {children}
                    <article className='text'>
                        <h1 className='introdction-txt TXT-heading2'>شركة ليبية متخصصة في  إنشاء برمجيات و تقدم عدة خدمات زي</h1>
                        <h1 className={`changing-word TXT-heading2 `} >
                            <p className={`${isShowen && 'showen'}`} style={{'--l':currentWord.l,'--m':currentWord.m,'--s':currentWord.s}}>{currentWord.word}</p>
                        </h1>
                    </article>
                    <div className={`velocity-logo ${showLogo && 'show-logo'}`}>
                        <img className='v-logo' src="/v-logo.png" alt="" />
                        {/* <h2 className={`TXT-heading`}>V</h2>
                        <h2 className='TXT-heading'>C</h2> */}
                    </div>
                    <a href="#services">
                        <button className='next-btn'>
                            <p className='TXT-heading3'>الاستمرار</p>
                            <span className={`TXT-heading2 ${showLogo && 'show'}`}>{MdKeyboardDoubleArrowDown({})}</span>
                        </button>  
                    </a>
                </div>
            </div>
        </section>
    )
}

export default AboutUs