import React, { useState } from 'react'

export const Header = ({isActive,setCurrentSection,cuurentSection}) => {
    const [isShowen,setIsShowen] = useState(false)
    const handleClick = (section) => {
        setIsShowen(false)
        setTimeout(()=>{
            setCurrentSection(section)
        },400)
    }
    return (
        <header className={`main-header container ${isShowen && 'showen'} ${isActive && 'active'}`}>
            <h2 className='logo'>VELOCITY</h2>
            <nav>
                <p className='icon' onClick={()=>setIsShowen(prev => !prev)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </p>
                <ul role='list' >
                    <li className={`TXT-normal ${cuurentSection === 'aboutUs' && 'active'}`} onClick={()=>handleClick('aboutUs')}>
                        <a href="#about-us">موضوعنا</a>
                    </li>
                    <li className={`TXT-normal ${cuurentSection === 'services' && 'active'}`} onClick={()=>handleClick('services')}>
                        <a href="#services">خدماتنا</a>
                    </li>
                    <li className={`TXT-normal ${cuurentSection === 'priorities' && 'active'}`} onClick={()=>handleClick('priorities')}>
                        <a href="#priorities">أولوياتنا</a>
                    </li>
                    <li className={`TXT-normal ${cuurentSection === 'contact' && 'active'}`} onClick={()=>handleClick('contact')}>
                        <a href="#contact">وين تلقانا</a>
                    </li>
                </ul>
            </nav>
        </header>
    )
}
