import React, { useEffect, useState } from 'react'
import '../styles/services.scss'
import { AiOutlineGlobal } from 'react-icons/ai'
import { IoPhonePortraitOutline } from 'react-icons/io5'
import { BiStore } from 'react-icons/bi'

export const Services = ({children,isActive}) => {
    const [showLogo,setShowLogo] = useState(false)
    const [currentCard,setCurrentCard] = useState(0)
    const [clInterval,setClInterval] = useState(null)
    useEffect(()=>{
        if(isActive){
            setTimeout(()=>{
                setShowLogo(true)
            },800)
            let interval = setInterval(()=>{
                setCurrentCard(prev => prev >= 2 ? 0 : prev + 1)
            },5000) 
            setClInterval(interval)
        }else {
            clearInterval(clInterval)
            setShowLogo(false)
        }
    },[isActive])
    return (
        <section className={`services ${isActive && 'active'}`} id="services">
            <div className='container services-container'>
                <section className='holder'>
                    {children}
                    <h2 className='main-heading'>خدماتنا</h2>
                    <div className='swiper' style={{'--t':currentCard}}>
                            <div className="card-holder" >
                            <article className={`card`}>
                                <div className='corners'></div>
                                <div className='icon'>{BiStore({})}</div>
                                <h2 className='main-txt TXT-heading3'>بناء متاجر إلكترونية</h2>
                                <div className='items'>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <img className='store-image' src="/store.png" alt="" />
                            </article>
                            <article className={`card`}>
                                <div className='corners'></div>
                                <div className='icon'>{IoPhonePortraitOutline({})}</div>
                                <h2 className='main-txt TXT-heading3'>برمجة تطبيقات</h2>
                                <img className='app-image' src="/app.png" alt="" />
                                <img className='male-avatar' src="/male_avatar.png" alt="" />
                                <img className='female-avatar' src="/female_avatar.png" alt="" />
                            </article>
                            <article className={`card`}>
                                <div className='corners'></div>
                                <div className='icon'>{AiOutlineGlobal({})}</div>
                                <h2 className='main-txt TXT-heading3'>تطوير مواقع ويب</h2>
                                <img className='computer-image' src="/computer.png" alt="" />
                                <div className='space'>
                                    <img className='rocket-1' src="/rocket.png" alt="" />
                                    <img className='rocket-2' src="/rocket.png" alt="" />
                                    <img className='rocket-3' src="/rocket.png" alt="" />
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className={`velocity-logo ${showLogo && 'show-logo'}`}>
                        <img className='v-logo' src="/v-logo.png" alt="" />
                        {/* <p>V</p>
                        <p>C</p> */}
                    </div>
                    <span className={`line ${showLogo && 'animate'}`}></span>
                </section>
            </div>
        </section>
    )
}
