import React, { useEffect, useRef, useState } from 'react'

export const Background = ({children,setCurrentSection}) => {
    const [scrollingDirection, setScrollingDirection] = useState('');
    const BackgroundRef = useRef(null);

    useEffect(() => {
        const app = document.querySelector('.animated-back-ground');
        const allSections = ['aboutUs','services','priorities','contact']
        let lastScrollPosition = app.scrollTop;
        let canScroll1 = true;
        let canScroll = true;

        const handleScroll = () => {
        if (!canScroll1) {
            return;
        }
        const scrollPosition = Math.floor(app.scrollTop);
        const WinHeight = window.innerHeight
        let prevIndex;

        if(scrollPosition >= 0 &&  scrollPosition <= WinHeight/4){
            prevIndex = 0
        }else if(scrollPosition >= WinHeight/4 && scrollPosition <= WinHeight*2 - WinHeight/4){
            prevIndex = 1
        }else if(scrollPosition >= WinHeight*2 - WinHeight/4 && scrollPosition <= WinHeight*3 - WinHeight/4){
            prevIndex = 2
        }else if(scrollPosition >= WinHeight*3 - WinHeight/4){
            prevIndex = 3
        }

        const currentScrollPosition = app.scrollTop;

        if (currentScrollPosition > lastScrollPosition) {
            setScrollingDirection('downwards');
            setCurrentSection(allSections[prevIndex + 1])
        } else {
            setScrollingDirection('upwards');
            setCurrentSection(allSections[prevIndex - 1])
        }

        lastScrollPosition = currentScrollPosition;

        setTimeout(() => {
            canScroll1 = false;
        }, 50);

        setTimeout(() => {
            canScroll1 = true;
            setScrollingDirection('');
        }, 1200);
        };

        const handleWheel = (event) => {
        event.preventDefault();

        if (!canScroll) {
            return;
        }

        canScroll = false;

        const scrollAmount = event.deltaY * 0.05;

        app.scrollBy({
            top: scrollAmount,
            behavior: 'smooth',
        });

        setTimeout(() => {
            canScroll = true;
        }, 1200);
        };

        app.addEventListener('scroll', handleScroll);
        app.addEventListener('wheel', handleWheel);

        return () => {
        app.removeEventListener('scroll', handleScroll);
        app.removeEventListener('wheel', handleWheel);
        };
    }, []);
    return (
        <div ref={BackgroundRef} className='animated-back-ground'>
            <article className={`transition-page ${scrollingDirection}`}>
                <div>
                <h2>VELO<span>C</span>ITY</h2>
                </div>
            </article>
            {children}
        </div>
    )
}
