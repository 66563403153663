import React, { useEffect, useState } from 'react'
import { BsTelephone,BsInstagram } from 'react-icons/bs'
import { LuFacebook } from "react-icons/lu";
import { AiOutlineMail } from 'react-icons/ai'
import { MdOutlineContentCopy } from 'react-icons/md' 
import '../styles/contact.scss'

export const Contact = ({children,isActive}) => {
    const [showLogo,setShowLogo] = useState(false)
    useEffect(()=>{
        if(isActive){
            setTimeout(()=>{
                setShowLogo(true)
            },1200)
        }else {
            setShowLogo(false)
        }
    },[isActive])


    function copyToClipboard(text) {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
    }
    return (
        <section className={`contact ${isActive && 'active'}`} id='contact'>
            <div className='container contact-container'>
                <section className='holder'>
                    {children}
                    <div className={`progress-circle ${showLogo && 'showen'}`}>
                        <img className='v-logo' src="/v-logo.png" alt="" />
                        {/* <p>V</p>
                        <p>C</p> */}
                        <svg className="progress" viewBox="0 0 100 100">
                            <circle className="progress-background" cx="50" cy="50" r="45"></circle>
                            <circle className={`progress-foreground ${showLogo && 'showen'}`} cx="50" cy="50" r="45"></circle>
                        </svg>
                    </div>
                    <div className={`line ${showLogo && 'showen'}`}></div>
                    <article className='contacts'>
                        <p className='detail TXT-normal'>
                            <span className='TXT-heading3' onClick={()=>copyToClipboard('0945397616')}>{MdOutlineContentCopy({})}</span>
                                0945397616
                            <span className='TXT-heading3'>{BsTelephone({})}</span>
                        </p>
                        <p className='detail TXT-normal'>
                            <span className='TXT-heading3' onClick={()=>copyToClipboard('velocity.software.development')}>{MdOutlineContentCopy({})}</span>
                                <a href="https://www.instagram.com/velocity.software.development/">velocity.software.development</a>
                            <span className='TXT-heading3'>{BsInstagram({})}</span>
                        </p>
                        <p className='detail TXT-normal'>
                            <span className='TXT-heading3' onClick={()=>copyToClipboard('velocity.software.development')}>{MdOutlineContentCopy({})}</span>
                                <a href="https://www.tiktok.com/@velocity.agency">velocity.software.development</a>
                            <span className='TXT-heading3'>{LuFacebook({})}</span>
                        </p>
                        <p className='detail TXT-normal'>
                            <span className='TXT-heading3' onClick={()=>copyToClipboard('info@velocity.ly')}>{MdOutlineContentCopy({})}</span>
                                info@velocity.ly
                            <span className='TXT-heading3'>{AiOutlineMail({})}</span>
                        </p>
                    </article>
                    <h2 className='query'>
                        عندك أي إستفسار؟
                    </h2>
                </section>
                <footer className='waves'>
                    <div className='wave1'></div>
                    <div className='wave2'></div>
                    <div className='wave3'></div>
                    <div className='wave4'></div>
                </footer>
                <div className='footer-bottom'></div>
                <p className='rights' dir='ltr'>&copy; 2023 Velocity. All rights reserved.</p>
            </div>
        </section>
    )
}
