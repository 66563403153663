import React, { useState } from 'react';
import './styles/Global.scss';
import { Header } from './components/Header';
import { Background } from './components/Background';
import AboutUs from './components/AboutUs';
import { Services } from './components/Services';
import { Priorities } from './components/Priorities';
import { Contact } from './components/Contact';

function App() {
  const [cuurentSection,setCurrentSection] = useState('aboutUs');

  return (
    <main className="App">
      <Background setCurrentSection={setCurrentSection}>
        <AboutUs isActive={cuurentSection === 'aboutUs'}>
          <Header isActive={cuurentSection === 'aboutUs'} setCurrentSection={setCurrentSection} cuurentSection={cuurentSection}/>
        </AboutUs>
        <Services isActive={cuurentSection === 'services'}>
          <Header isActive={cuurentSection === 'services'} setCurrentSection={setCurrentSection} cuurentSection={cuurentSection}/>
        </Services>
        <Priorities isActive={cuurentSection === 'priorities'}>
          <Header isActive={cuurentSection === 'priorities'} setCurrentSection={setCurrentSection} cuurentSection={cuurentSection}/>
        </Priorities>
        <Contact isActive={cuurentSection === 'contact'}>
          <Header isActive={cuurentSection === 'contact'} setCurrentSection={setCurrentSection} cuurentSection={cuurentSection}/>
        </Contact>
      </Background>
    </main>
  );
}

export default App;